.qrcode-centralizer {
    display: flex;
    padding: 2rem;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9; 
}

.background-qrcode {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    background-color: #ffffff;
    border-radius: 10px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    overflow: hidden;
    padding: 20px;
    margin-bottom: 20px;
    box-sizing: border-box; 
}

.qrcode-name {
    text-align: start;
    font-size: 2.5rem;
    font-weight: 700;
    color: #333333;
    margin: 0;
    padding-bottom: 10px;
    border-bottom: 2px solid #e0e0e0; 
}

.qrcode-diviser {
    display: flex;
    align-items: center; 
    gap: 2rem; 
    margin-top: 2rem;
}

.container-img-qr {
    flex-shrink: 0; 
}

.container-img-qr img {
    width: auto;
    height: auto;
    min-width: 100px; 
    min-height: 100px;
    max-width: 100%;
    border-radius: 8px; 
    padding: 1rem;
    border: 1px solid #ddd;
}

.qrcode-container-info {
    flex: 1; 
    display: flex;
    flex-direction: column;
}

.qrcode-description {
    font-size: 1.2rem;
    color: #555555;
    line-height: 1.6;
    margin: 1rem; 
}


@media (max-width: 768px) {
    .background-qrcode {
        
        padding: 1rem; 
    }

    .qrcode-name {
        font-size: 2rem;
    }

    .qrcode-diviser {
        flex-direction: column;
        gap: 1rem; 
    }

    .container-img-qr img {
        width: 100%; 
        min-width: auto; 
    }

    .qrcode-description {
        font-size: 1rem; 
    }
}
