.btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
    transition: opacity 0.3s ease;
}

.btn-close:hover {
    opacity: 1;
}



.container {
    padding-top: 5rem;
}

.custom-button {
    background-color: #FFDF2B;
    color: black;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 16px;
    font-family: Arial;
    text-decoration: none;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.custom-button:hover {
    background-color: #e74c3c;
}

.spinner-border {
    color: white;
}





