
.background-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.button-container {
    position: absolute;
    bottom: 20px;
    width: 100%;
}

.content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container {
    padding-top: 5rem;
}

.custom-opacity {
    margin: 10px;
    background-color: #FFF29E;
    border: black;
    box-shadow: #FFF29E -0.7rem 1.5rem 0px 0px;
}

.flag-text {
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    align-items: center;
}

.flag-text img {
    margin-right: 10px;
    width: 125px;
    height: 84px;
}

.homepage {
    position: relative;
    text-align: center;
    height: 100vh;
    overflow: hidden;
}

.link-decoration {
    justify-content: center;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    width: 40%;
}

.logos {
    height: 70px;
    padding-right: 1rem;
}

.logo-turismo {
    position: absolute;
    top: 20px;
    right: 20px;
    align-items: center;
}

.logo-turismo img {
    width: 20rem;
}

.patrocinio {
    background-image: url("../../../../img/home/GasparHomePage.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 1rem;
    font-size: 30px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 445px;
}

.patrocinio-logo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2.5rem;
    width: 100%;
    max-width: 1400px;
    box-sizing: border-box;
    padding: 0 20px;
}

.patrocinio-logo img {
    width: 20vh;
    margin: 1rem;
    max-width: 100%;
}

.popup-container {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 300px;
    max-height: 80%;
    overflow-y: auto;
    background-color: #FFF29E;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px 8px 0 0;
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
}

.popup-container.closed {
    transform: translateY(calc(100% - 40px));
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #051D82;
    opacity: 0.7;
    margin-bottom: 0.2rem;
    margin-right: 0.2rem;
}

.popup-close-btn {
    position: fixed;
    right: 1rem;
    background-color: transparent;
    color: #000;
    border: none;
    cursor: pointer;
    font-size: 20px;
    z-index: 1001;
    opacity: 0.2;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.popup-close-btn:hover {
    opacity: 1;
    transform: scale(1.1);
    border-radius: 50%;
}

.popup-content {
    padding: 15px;
}

.popup-section {
    margin-bottom: 15px;
}

.popup-section p {
    font-size: 14px;
    line-height: 1.5;
}

.popup-toggle-btn {
    background-color: transparent;
    color: white;
    border: none;
    padding: 5px;
    cursor: pointer;
    font-size: 18px;
}

.popup-toggle-btn.minimized {
    font-size: 24px;
    padding: 0;
}

.title-text {
    margin-top: 50px;
    font-size: 70px;
}

.titulo {
    background-image: url("../../../../img/logos/fundotitulos.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    overflow: hidden;
    padding: 1rem 1.5rem;
}

.background-home-cards-fundo {
    background-color: rgb(5, 29, 130);
    padding-top: .5rem;
    padding-bottom: .5rem;
    width: 100%;
}

.cards-importantes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.cards-importantes-col {
    width: 30%;
    justify-content: center;
}


@media (max-width: 768px) {
    .button-container button {
        width: 80%;
    }

    .cards-importantes {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 0;
        gap: 0;
    }

    .cards-importantes-col {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .custom-opacity {
        background-color: #FFF29E;
        box-shadow: none;
    }

    .flag-text img {
        position: relative;
        width: 100px;
        height: 70px;
    }

    .logo-turismo img {
        width: 15rem;
    }

    .link-decoration {
        width: 95%;
    }

    .map img {
        object-fit: contain;
    }

    .patrocinio {
        font-size: 24px;
        height: auto;
        padding-top: 1rem;
    }

    .patrocinio-logo img {
        width: 15vh;
        margin: 0.5rem;
    }

    .popup-close-btn {
        position: fixed;
        right: 0;
        background-color: transparent;
        color: #000;
        border: none;
        cursor: pointer;
        font-size: 20px;
        z-index: 1001;
        opacity: 0.2;
    }

    .title-text {
        font-size: 40px;
        margin-top: 20px;
    }
}

@media (max-width: 480px) {
    .button-container button {
        width: 90%;
    }

    .flag-text img {
        width: 80px;
        height: 50px;
    }

    .logo-turismo {
        right: 20px;
    }

    .logo-turismo img {
        width: 10rem;
    }

    .link-decoration {
        width: 95%;
    }

    .map img {
        object-fit: contain;
    }

    .patrocinio {
        font-size: 20px;
        padding-top: 0.5rem;
    }

    .patrocinio-logo img {
        width: 10vh;
        margin: 0.5rem;
    }

    .title-text {
        font-size: 30px;
        margin-top: 10px;
    }
}

.btn-initial-page {
    --color: #F9E400;
    background-color: #e0a830;
    font-family: inherit;
    display: inline-block;
    width: 6em;
    height: 2.6em;
    line-height: 2.5em;
    overflow: hidden;
    cursor: pointer;
    margin: 20px;
    font-size: 17px;
    z-index: 1;
    color: black;
    border: none;
    border-radius: 6px;
    position: relative;
    font-weight: bold;
}

.btn-initial-page::before {
    position: absolute;
    content: "";
    background: var(--color);
    width: 150px;
    height: 200px;
    z-index: -1;
    border-radius: 50%;
}

.btn-initial-page:hover {
    color: black;
}

.btn-initial-page:before {
    top: 100%;
    left: 100%;
    transition: 0.3s all;
}

.btn-initial-page:hover::before {
    top: -30px;
    left: -30px;
}

/* Estilos para a barra de rolagem */
.card-text {
    max-height: 100px;
    overflow-y: auto;
    /* Adicione um pouco de padding para melhorar a aparência */
    padding-right: 10px;
}

/* Webkit (Chrome, Safari) */
.card-text::-webkit-scrollbar {
    width: 8px; /* Largura da barra de rolagem */
}

.card-text::-webkit-scrollbar-track {
    background: #f1f1f1; /* Cor de fundo da faixa da barra de rolagem */
    border-radius: 10px; /* Bordas arredondadas da faixa */
}

.card-text::-webkit-scrollbar-thumb {
    background: #888; /* Cor da parte que se move da barra de rolagem */
    border-radius: 10px; /* Bordas arredondadas do controle */
}

/* Firefox */
.card-text {
    scrollbar-width: none; /* Largura da barra de rolagem */
    scrollbar-color: #ffda05 #f1f1f1; /* Cor do controle e cor de fundo da faixa */
}

.modal-dialog {
    max-width: 100%;
}

.modal-content {
    width: 100%;
    height: auto;
}

.modal-body {
    max-height: 80vh;
    overflow-y: auto;
}
