.modal {
    display: none;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
}

.modal-content {
    background-color: #fefefe;
    margin-left: 24%;
    padding: 1rem;
    border: 1px solid #888;
    width: 100%;
    max-width: 45rem;
    animation-name: modal-animation;
    animation-duration: 0.3s;
}

.cards-principal-lists {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 0.5rem;
    margin-top: .3rem;
}

@keyframes modal-animation {
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.cards-principal-lists {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 0.5rem;
    margin-bottom: .2rem;
}

.cards-principal-lists .card {
    margin: 0.5rem;
    flex: 1 1 calc(30% - 0.5rem);
    max-width: calc(30% - 0.5rem);
}

.ad-banner-container {
    width: 100%;
    margin: 0.7rem;
    display: flex;
    justify-content: center;
}

@media (max-width: 1024px) {
    .cards-principal-lists .card {
        flex: 1 1 calc(50% - 1rem);
        max-width: calc(40% - 1rem);
    }
}


@media (max-width: 768px) {
    .cards-principal-lists .card {
        flex: 1 1 100%;
        max-width: 100%;
    }

    .ad-banner-container {
        flex-direction: column;
    }

    .modal-content {
        margin: 0;
        height: 40rem;
    }

    .modal {
        height: 100%;
    }

}
.card-header {
    background-color: #007e7e;
}

.card-info {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}


.card-info img {
    padding-top: 0.5rem;
    width: 60%;
    background-color: #8ce0e0;
    height: auto;
    object-fit: cover;
}

.cards-principal {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 100%;
}
.card {
    flex: 0 0 calc(30% - 30px);
    margin-bottom: 2rem;
    margin-top: 2rem;
    border-radius: 13px;
    border: none;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    overflow: hidden;
}

.card:hover {
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
    transform: scale(1.04);
}

.card-body {
    background-color: #46A7A7;
}

.card-text {
    color: white;
    font-family: Arial;
}

.card-title {
    color: #FFDF2B;
    font-family: "Arial Black";
}

.cards {
    padding-top: 5rem !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background: #EAEDED;
}

.carousel-item img {
    height: 300px;
    object-fit: cover;
}
@media (max-width: 992px) {
    .card {
        flex: 0 0 calc(45% - 20px);
    }

    .card.active-hover {
        box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
        transform: scale(1.05);
    }
}

@media (max-width: 768px) {
    .card {
        flex: 0 0 calc(100% - 20px);
    }

    .card.active-hover {
        box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
        transform: scale(1.05);
    }
}
