.ad-save-button {
    width: 120px;
    height: 40px;
    border-radius: 30px;
    border: none;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.13);
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 600;
    cursor: pointer;
    color: white;
    background: linear-gradient(to left, rgb(5, 146, 18), rgb(6, 208, 1));
    letter-spacing: 0.7px;
}

.ad-save-button:hover .arrow {
    animation: slide-in-left 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.ad-save-button:active {
    transform: scale(0.97);
}

.ad-edit-cancel-button {
    width: 120px;
    height: 40px;
    border-radius: 30px;
    border: none;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.13);
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 600;
    cursor: pointer;
    color: white;
    background: linear-gradient(to left, rgb(53, 47, 68), rgb(92, 84, 112));
    letter-spacing: 0.7px;
}

.ad-edit-cancel-button:hover {
    background: linear-gradient(to left, rgb(92, 84, 112), rgb(53, 47, 68));
}

.ad-edit-cancel-button:active {
    transform: scale(0.97);
}

.ad-edit-edit-name {
    border: 2px solid transparent;
    width: 100%;
    height: 2.5em;
    padding-left: 0.8em;
    outline: none;
    overflow: hidden;
    background-color: #F3F3F3;
    border-radius: 10px;
    transition: all 0.5s;
}

.ad-edit-edit-description {
    border: 2px solid transparent;
    width: 100%;
    padding-left: 0.8em;
    outline: none;
    overflow: hidden;
    background-color: #F3F3F3;
    border-radius: 10px;
    transition: all 0.5s;
}

.ad-edit-confirmation-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(to left, rgb(5, 146, 18), rgb(6, 208, 1));
    color: white;
    font-weight: 600;
    letter-spacing: 0.7px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
}

.ad-edit-delete-image-button {
    width: 50%;
    height: 40px;
    border-radius: 30px;
    border: none;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.13);
    align-items: center;
    justify-content: center;
    font-weight: 600;
    cursor: pointer;
    color: white;
    background: linear-gradient(to left, rgb(199, 0, 57), rgb(249, 76, 16));
    letter-spacing: 0.7px;
}

.ad-edit-delete-image-button:active {
    transform: scale(0.97);
}

.ad-edit-delete-image-button:hover {
    background: linear-gradient(to left, rgb(249, 76, 16), rgb(248, 222, 34));
}

.ad-edit-component-buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.ad-edit-edit-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100px;
    height: 40px;
    border: none;
    padding: 0 20px;
    background: linear-gradient(to left, rgb(53, 114, 239), rgb(58, 190, 249));
    color: white;
    font-weight: 500;
    border-radius: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition-duration: .3s;
    margin-top: 0.2rem;
}

.ad-edit-edit-button svg {
    width: 13px;
    position: absolute;
    right: 0;
    margin-right: 20px;
    fill: white;
    transition-duration: .3s;
}

.ad-edit-edit-button:hover {
    color: transparent;
}

.ad-edit-edit-button:hover svg {
    right: 43%;
    margin: 0;
    padding: 0;
    border: none;
    transition-duration: .3s;
}

.ad-edit-edit-button:active {
    transform: translate(3px, 3px);
    transition-duration: .3s;
    box-shadow: 2px 2px 0px rgb(5, 12, 156);
}

.ad-edit-delete-button {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    width: 55px;
    height: 40px;
    border-radius: 15px;
    background: linear-gradient(to left, rgb(255, 0, 0), rgb(255, 95, 95));
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition-duration: .3s;
    cursor: pointer;
    transition-duration: .3s;
    margin-top: 0.2rem;
}

.ad-edit-delete-button-bottom {
    width: 15px;
}

.ad-edit-delete-button-top {
    width: 17px;
    transform-origin: right;
    transition-duration: 0.3s;
}

.ad-edit-delete-button:hover .ad-edit-delete-button-top {
    transform: rotate(45deg);
}

.ad-edit-delete-button:active {
    transform: scale(0.9);
}

.ad-edit-open-file {
    background-color: rgb(255, 255, 255);
    width: 50%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 30px;
    border: 0.2rem solid lightgrey;
}

.ad-edit-file-wrapper {
    width: 15px;
    height: auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
}

.ad-edit-file-wrapper svg {
    width: 100%;
}

.ad-edit-file-front {
    position: absolute;
    width: 100%;
    height: 70%;
    border: 2px solid rgb(0, 0, 0);
    border-bottom: 1px solid black;
    transform: skewX(-40deg);
    transform-origin: bottom right;
    background-color: white;
    transition: all 0.5s;
    bottom: 0;
}

.ad-edit-open-file:hover .ad-edit-file-front {
    height: 50%;
    transform-origin: bottom right;
    transform: skewX(-55deg);
}

.ad-edit-open-file:hover {
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.048);
}

@keyframes slide-in-left {
    0% {
        transform: translateX(-10px);
        opacity: 0;
    }

    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

.ad-edit-container {
    padding: 20px;
    background: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ad-edit-container h2 {
    font-size: 24px;
}

.ad-edit-category-list {
    display: flex;
    flex-direction: column;
}

.ad-edit-category-item {
    margin-bottom: 20px;
}

.ad-edit-component-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.ad-edit-component-list li {
    background: #fff;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.ad-edit-select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.ad-edit-centralize-modal .ad-edit-modal {
    background-color: whitesmoke;
}

.ad-edit-modal {
    background: whitesmoke;
    padding: 2rem;
    border-radius: 10px;
    width: 200vh;
    height: 100vh;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    gap: 1rem;
    margin-left: 2rem;
}

.ad-edit-modal-content {

    display: flex;
    justify-content: space-between;
    height: 87%;
    overflow-y: auto;
}

.ad-edit-left-column,
.ad-edit-right-column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
}

.ad-edit-left-column {
    width: 50%;
}

.ad-edit-right-column {
    width: 50%;
}

.ad-edit-modal-footer {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
}

.ad-edit-modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ad-edit-modal h2 {
    width: 100%;
    font-size: 24px;
    text-align: center;
    background-color: #051D82;
    border-radius: 10px;
    color: white;
}

.ad-edit-select {
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 1rem;
    appearance: none;
    background-color: #eee;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23000' d='M2 0L0 2h4zM0 3l2 2 2-2z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    background-size: 10px;
    cursor: pointer;
    box-shadow: 0 0.2rem lightgrey;
}

.ad-edit-select option {
    font-weight: normal;
    border-radius: 1rem;
}

.ad-edit-carousel {
    background-color: lightgrey;
    max-width: 592px;
    max-height: 160px;
    border-radius: 1rem;
}

.ad-edit-carousel img {
    object-fit: fill;
    height: 160px;
    width: 592px;
    border-radius: 1rem;
}

.ad-edit-carousel .slide {
    position: relative;
    width: 100%;
}

.ad-edit-carousel-container {
    position: relative;
    width: 100%;
}

.ad-edit-manage-image-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.scrollable {
    overflow-x: auto;
    white-space: nowrap;
}

.custom-scroll {
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin;
    scrollbar-color: gray rgb(182, 182, 182);
}

.ad-edit-name-input {
    display: flex;
    flex-direction: column;
}

.ad-edit-name-input input {
    background-color: #eee;
    border: none;
    padding: 0.5rem;
    font-size: 1rem;
    width: 60%;
    border-radius: 1rem;
    color: black;
    box-shadow: 0 0.2rem lightgrey;
    cursor: pointer;
}

.ad-edit-name-input input:focus {
    outline-color: grey;
}

.ad-edit-name-input label {
    font-weight: 500;
    padding-left: 0.5rem;
}

.ad-edit-description-input {
    display: flex;
    flex-direction: column;
}

.ad-edit-description-input textarea {
    background-color: #eee;
    border: none;
    padding: 0.5rem;
    font-size: 1rem;
    width: 80%;
    height: 10rem;
    border-radius: 1rem;
    color: black;
    box-shadow: 0 0.2rem lightgrey;
    cursor: pointer;
}

.ad-edit-description-input label {
    font-weight: 500;
    padding-left: 0.5rem;
}

.ad-edit-description-input textarea:focus {
    outline-color: grey;
}

.ad-edit-value-input {
    flex-direction: column;
    display: flex;
}

.ad-edit-value-input input {
    background-color: #eee;
    border: none;
    padding: 1rem;
    font-size: 1rem;
    width: 13rem;
    border-radius: 1rem;
    color: black;
    box-shadow: 0 0.2rem lightgrey;
    cursor: pointer;
}

.ad-edit-value-input input:focus {
    outline-color: grey;
}

.ad-edit-value-input label {
    font-weight: bold;
    padding-left: 0.5rem;
}

.ad-edit-category-input label {
    font-weight: bold;
    padding-left: 0.5rem;
}

.ad-edit-mapbox {
    display: flex;
}

.ad-edit-search-input {
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
}

.ad-edit-search-input input {
    background-color: #eee;
    border: none;
    padding: 0.5rem;
    font-size: 1rem;
    width: 100%;
    border-radius: 1rem;
    color: black;
    box-shadow: 0 0.2rem lightgrey;
    cursor: pointer;
}

.ad-edit-search-input input:focus {
    outline-color: grey;
}

.ad-edit-search-input button {
    width: 30%;
    height: 40px;
    border-radius: 30px;
    border: none;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.13);
    align-items: center;
    justify-content: center;
    font-weight: 600;
    cursor: pointer;
    color: white;
    background: linear-gradient(to left, rgb(8, 131, 149), rgb(55, 183, 195));
    letter-spacing: 0.7px;
}

.ad-edit-search-input button:active {
    transform: scale(0.97);
}

.ad-edit-search-input button:hover {
    background: linear-gradient(to left, rgb(55, 183, 195), rgb(8, 131, 149));
}

.ad-edit-url-input {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.ad-edit-url-input input {
    background-color: #eee;
    border: none;
    padding: 1rem;
    font-size: 1rem;
    border-radius: 1rem;
    color: black;
    box-shadow: 0 0.2rem lightgrey;
    cursor: pointer;
    width: 80%;
}

.ad-edit-url-input input:focus {
    outline-color: grey;
}

.ad-edit-url-input label {
    font-weight: 500;
    padding-left: 0.5rem;
}
