.ad-register-button {
    width: 120px;
    height: 40px;
    border-radius: 30px;
    border: none;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.13);
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 600;
    cursor: pointer;
    color: white;
    background: linear-gradient(to left, rgb(26, 83, 25), rgb(80, 141, 78));
    letter-spacing: 0.7px;
    position: absolute;
    right: 3rem;
    bottom: 1rem;
}

.ad-register-button:hover .arrow {
    animation: slide-in-left 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.ad-register-button:active {
    transform: scale(0.97);
}

.ad-open-file {
    background-color: rgb(255, 255, 255);
    width: 20rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    border: 1px solid rgb(217, 217, 217);
    font-size: 15px;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.ad-file-wrapper {
    width: 15px;
    height: auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
}

.ad-file-wrapper svg {
    width: 100%;
}

.ad-file-front {
    position: absolute;
    width: 100%;
    height: 70%;
    border: 2px solid rgb(0, 0, 0);
    border-bottom: 1px solid black;
    transform: skewX(-40deg);
    transform-origin: bottom right;
    background-color: white;
    transition: all 0.5s;
    bottom: 0;
}

.ad-open-file:hover .ad-file-front {
    height: 50%;
    transform-origin: bottom right;
    transform: skewX(-55deg);
}

.ad-open-file:hover {
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.048);
}

.ad-input-group {
    font-family: 'Segoe UI', sans-serif;
    margin: 1em 0 1em 0;
    max-width: 190px;
    position: relative;
}

.ad-input-group input {
    font-size: 100%;
    padding: 0.8em;
    outline: none;
    border: 2px solid rgb(200, 200, 200);
    background-color: transparent;
    border-radius: 20px;
    width: 100%;
}

.ad-input-group label {
    font-size: 100%;
    position: absolute;
    left: 0;
    padding: 0.8em;
    margin-left: 0.5em;
    pointer-events: none;
    transition: all 0.3s ease;
    color: rgb(100, 100, 100);
}

.ad-input-group :is(input:focus, input:valid) ~ label {
    transform: translateY(-50%) scale(.9);
    margin: 0em;
    margin-left: 1.3em;
    padding: 0.4em;
    background-color: #e8e8e8;
}

.ad-input-group :is(input:focus, input:valid) {
    border-color: rgb(150, 150, 200);
}

.ad-form-section {
    background: #f5f5f5;
    border-radius: 10px;
    font-weight: bold;
    box-shadow: 0 0 10px rgba(126, 112, 112, 0.1);
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.ad-model-style {
    display: flex;
    flex-direction: column;
    width: 65%;
}


.ad-name-input {
    width: 15rem;
}

.ad-name-input input {
    background-color: #eee;
    border: none;
    padding: 1rem;
    font-size: 1rem;
    border-radius: 1rem;
    color: black;
    box-shadow: 0 0.2rem lightgrey;
    cursor: auto;
    width: 100%;
}

.ad-name-input input:focus {
    outline-color: grey;
}

.ad-description-input {
    width: 20rem;
}

.ad-description-input textarea {
    background-color: #eee;
    border: none;
    padding: 1rem;
    font-size: 1rem;
    border-radius: 1rem;
    color: black;
    box-shadow: 0 0.2rem lightgrey;
    cursor: auto;

}

.ad-description-input textarea:focus {
    outline-color: grey;
}

.ad-category-select-wrapper {
    width: 20rem;
    cursor: pointer;
    position: relative;
    color: black;
    align-items: center;
    font-weight: normal;
    justify-content: center;

}

.ad-category-selected {
    background-color: #eee;
    padding: 1rem;
    border-radius: 1rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0.2rem lightgrey;
}

.ad-category-arrow {
    height: 10px;
    transform: rotate(-90deg);
    width: 25px;
    fill: black;
    transition: 300ms;
}

.ad-category-options {
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    padding: 5px;
    background-color: #eee;
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms, visibility 300ms;
    box-shadow: 0 0.2rem lightgrey;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    z-index: 100;
}

.ad-category-select-wrapper:hover .ad-category-options {
    opacity: 1;
    visibility: visible;
}

.ad-category-select-wrapper:hover .ad-category-selected .ad-category-arrow {
    transform: rotate(0deg);
}

.ad-category-option {
    border-radius: 5px;
    padding: 5px;
    transition: 300ms;
    background-color: #eee;
    width: 100%;
    font-size: 1rem;
}

.ad-category-option:hover {
    background-color: #ddd;
}

.ad-category-options input[type="radio"] {
    display: none;
}

.ad-category-options label {
    display: inline-block;
    width: 100%;
    color: black;
    font-weight: normal;
}

.ad-category-options label::before {
    content: attr(data-txt);
    color: black;
}

.ad-category-options input[type="radio"]:checked + label {
    background-color: #ddd;
}

.ad-empty-container {
    width: 100%;
    padding: 0.5rem;
}

.ad-empty-banner {
    width: 592px;
    height: 160px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 1rem;
    position: relative;
    margin-left: 50%;
    transform: translateX(-50%);
    flex-direction: column;
    background-color: lightgrey;
}

.ad-form-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    position: relative;
    width: 100%;
    padding: 0.5rem;
}

.ad-url-input {
    width: 20rem;
}

.ad-url-input input {
    background-color: #eee;
    border: none;
    padding: 1rem;
    font-size: 1rem;
    border-radius: 1rem;
    color: black;
    box-shadow: 0 0.2rem lightgrey;
    cursor: auto;
    width: 100%;
}

.ad-url-input input:focus {
    outline-color: grey;
}
