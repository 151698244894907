.map-register-button {
    width: 120px;
    height: 40px;
    border-radius: 30px;
    border: none;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.13);
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 600;
    cursor: pointer;
    color: white;
    background: linear-gradient(to left, rgb(26, 83, 25), rgb(80, 141, 78));
    letter-spacing: 0.7px;
}

.map-register-button svg {
    margin-left: .2rem;
}

.map-register-button:hover .arrow {
    animation: slide-in-left 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.map-register-button:active {
    transform: scale(0.97);
}

.map-open-file {
    background-color: rgb(255, 255, 255);
    width: 12rem;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    border: 1px solid rgb(217, 217, 217);
    font-size: 15px;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 10px;
}

.map-file-wrapper {
    width: 15px;
    height: auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
}

.map-file-wrapper svg {
    width: 100%;
}

.map-file-front {
    position: absolute;
    width: 100%;
    height: 70%;
    border: 2px solid rgb(0, 0, 0);
    border-bottom: 1px solid black;
    transform: skewX(-40deg);
    transform-origin: bottom right;
    background-color: white;
    transition: all 0.5s;
    bottom: 0;
}

.map-open-file:hover .map-file-front {
    height: 50%;
    transform-origin: bottom right;
    transform: skewX(-55deg);
}

.map-open-file:hover {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.048);
}

.map-input-group {
    font-family: 'Segoe UI', sans-serif;
    margin: 1em 0 1em 0;
    max-width: 190px;
    position: relative;
}

.map-input-group input {
    font-size: 100%;
    padding: 0.8em;
    outline: none;
    border: 2px solid rgb(200, 200, 200);
    background-color: transparent;
    border-radius: 20px;
    width: 100%;
}

.map-input-group label {
    font-size: 100%;
    position: absolute;
    left: 0;
    padding: 0.8em;
    margin-left: 0.5em;
    pointer-events: none;
    transition: all 0.3s ease;
    color: rgb(100, 100, 100);
}

.map-input-group :is(input:focus, input:valid) ~ label {
    transform: translateY(-50%) scale(.9);
    margin: 0 0 0 1.3em;
    padding: 0.4em;
    background-color: #e8e8e8;
}

.map-input-group :is(input:focus, input:valid) {
    border-color: rgb(150, 150, 200);
}

.map-confirmation-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(to left, rgb(26, 83, 25), rgb(80, 141, 78));
    color: white;
    font-weight: 600;
    letter-spacing: 0.7px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
}

.map-form-section {
    background: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}


.map-left-column {
    display: flex;
    flex-direction: column;
    width: 60%;
    gap: 0.5rem;
}

.map-mapbox {
    display: block;
    width: 100%;
}

.map-right-column {
    width: 40%;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.map-right-column label {
    font-weight: 500;
}

.map-name-input {
    width: 100%;
}

.map-name-input input {
    background-color: #eee;
    border: none;
    padding: 1rem;
    font-size: 1rem;
    border-radius: 1rem;
    color: black;
    box-shadow: 0 0.2rem lightgrey;
    cursor: pointer;
    width: 80%;
}

.map-name-input input:focus {
    outline-color: grey;
}

.map-description-input {
    width: 100%;
}

.map-description-input textarea {
    background-color: #eee;
    border: none;
    padding: 1rem;
    font-size: 1rem;
    border-radius: 1rem;
    color: black;
    box-shadow: 0 0.2rem lightgrey;
    cursor: pointer;
    width: 80%;

}

.map-description-input textarea:focus {
    outline-color: grey;
}

.map-value-input input {
    background-color: #eee;
    border: none;
    padding: 1rem;
    font-size: 1rem;
    border-radius: 1rem;
    color: black;
    box-shadow: 0 0.2rem lightgrey;
    cursor: pointer;
}

.map-value-input input:focus {
    outline-color: grey;
}

.map-category-select-wrapper {
    width: 15rem;
    cursor: pointer;
    position: relative;
    color: black;
    align-items: center;
    justify-content: center;
}

.map-category-selected {
    background-color: #eee;
    padding: 1rem;
    border-radius: 1rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0.2rem lightgrey;
    cursor: pointer;
}

.map-category-arrow {
    height: 10px;
    transform: rotate(-90deg);
    width: 25px;
    fill: black;
    transition: 300ms;
}

.map-category-options {
    display: none;
    border-radius: 1rem;
    padding: 5px;
    background-color: #eee;
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms, visibility 300ms;
    box-shadow: 0 0.2rem lightgrey;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    z-index: 100;
}

.map-category-select-wrapper:hover .map-category-options,
.map-category-options:hover {
    opacity: 1;
    visibility: visible;
    display: flex;
    flex-direction: column;
}

.map-category-select-wrapper:hover .map-category-selected .map-category-arrow {
    transform: rotate(0deg);
}

.map-category-option {
    border-radius: 5px;
    padding: 5px;
    transition: 300ms;
    background-color: #eee;
    width: 100%;
    font-size: 1rem;
}

.map-category-option:hover {
    background-color: #ddd;
}

.map-category-options input[type="radio"] {
    display: none;
}

.map-category-options label {
    display: inline-block;
    width: 100%;
    color: black;
    font-weight: normal;
}

.map-category-options label::before {
    content: attr(data-txt);
    color: black;
}

.map-category-options input[type="radio"]:checked + label {
    background-color: #ddd;
}

.map-search-input {
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    position: relative;
}

.map-search-bar input {
    background-color: #eee;
    border: none;
    padding: 0.5rem;
    font-size: 1rem;
    width: 40%;
    max-height: 2.5rem;
    border-radius: 1rem;
    color: black;
    box-shadow: 0 0.2rem lightgrey;
    cursor: pointer;
}

.map-search-bar input:focus {
    outline-color: grey;
}

.map-search-input button {
    width: 30%;
    height: 40px;
    border-radius: 30px;
    border: none;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.13);
    align-items: center;
    justify-content: center;
    font-weight: 600;
    cursor: pointer;
    color: white;
    background: linear-gradient(to left, rgb(8, 131, 149), rgb(55, 183, 195));
    letter-spacing: 0.7px;
}

.map-search-input button:active {
    transform: scale(0.97);
}

.map-search-input button:hover {
    background: linear-gradient(to left, rgb(55, 183, 195), rgb(8, 131, 149));
}

.map-autocomplete-dropdown {
    border-radius: 1rem;
    padding: 5px;
    background-color: #ffffff;
    position: relative;
    width: max-content;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 0.2rem lightgrey;
    z-index: 100;
}

.map-autocomplete-dropdown li {
    border-radius: 5px;
    transition: 300ms;
    background-color: #eee;
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    flex-direction: row;
}

.map-autocomplete-dropdown li:hover {
    background-color: #ddd;
}

.map-search-bar {
    display: flex;
    flex-direction: row;
}

.pac-container {
    display: none !important;
}
