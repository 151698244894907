.ad-banner-container-ad {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.ad-banner-container-ad.single-ad {
    justify-content: center;
}

.ad-banner-ad {
    width: 592px;
    height: 160px;
    margin: .2rem;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 1rem;
}

.ad-content-link-ad {
    text-decoration: none;
}

.ad-banner-image-ad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 768px) {
    .ad-banner-ad {
        flex: 1 1 100%;
        height: 8rem;
        margin-left: 0;
        margin-right: 0;
        width: 490px;
    }
}

@media (max-width: 480px) {
    .ad-banner-ad {
        height: 6rem;
        margin-left: 0;
        margin-right: 0;
        width: 50%;
    }

}


