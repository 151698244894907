.dash-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f4f4f9;
    font-family: Arial, sans-serif;
    width: 100%;
}

.dash-header {
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: #051D82;
    color: white;
    height: 4rem;
    flex-direction: row;
    width: 100%;
}

.dash-header h1 {
    font-family: Roboto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.dash-logout-header {
    display: flex;
    position: absolute;
    right: 1rem;
}

.dash-logout-button {
    width: 120px;
    height: 40px;
    border-radius: 30px;
    border: none;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.13);
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 600;
    cursor: pointer;
    color: white;
    background: linear-gradient(to left, rgb(249, 76, 16), rgb(248, 222, 34));
    letter-spacing: 0.7px;
    position: relative;
}

.dash-logout-button:hover {
    background: linear-gradient(to left, rgb(199, 0, 57), rgb(249, 76, 16));
}

.dash-logout-button:active {
    transform: scale(0.97);
}

.dash-home-header {
    display: flex;
    position: absolute;
    left: 1rem;
}

.dash-home-button {
    width: fit-content;
    height: 40px;
    border-radius: 30px;
    border: none;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.13);
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 600;
    cursor: pointer;
    color: white;
    background: linear-gradient(to left, rgb(3, 52, 110), rgb(110, 172, 218));
    letter-spacing: 0.7px;
    position: relative;
}

.dash-home-button:hover {
    background: linear-gradient(to left, rgb(110, 172, 218), rgb(3, 52, 110));
}

.dash-home-button:active {
    transform: scale(0.97);
}

.dash-main {
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
}

.dash-section {
    flex: 1;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.dash-section h2 {
    margin-top: 0;
}

.dash-section:last-child {
    margin-right: 0;
}

.dash-switch-header {
    display: flex;
    position: absolute;
    right: 9rem;
}

.dash-switch-button {
    width: 11rem;
    height: 40px;
    border-radius: 30px;
    border: none;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.13);
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 600;
    cursor: pointer;
    color: white;
    background: linear-gradient(to left, rgb(66, 66, 66), rgb(118, 118, 118));
    letter-spacing: 0.7px;
    position: relative;
}

.dash-switch-button:hover {
    background: linear-gradient(to left, rgb(118, 118, 118), rgb(66, 66, 66));
}

.dash-switch-button:active {
    transform: scale(0.97);
}
