.bg-blue {
    background-color: #051D82;
}

.navbar-brand .logo {
    height: 40px;
}

.nav-logo {
    height: 18px;
    margin-right: 0.8rem;
}

.navbar-nav .nav-link {
    color: white;
    display: flex;
    align-items: center;
    font-size: 1.3rem;
}

.navbar-nav .nav-link:hover {
    color: rgb(59, 216, 255);
}

.navbar-toggler {
    border: none;
}

.form-control {
    transition: width 0.3s ease;
}

.form-control:focus {
    width: 200px;
}

.navbar-toggler {
    border: none;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

