.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #051D82;
}

.login-form {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
}

.login-form h2 {
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
}

.login-form-group {
    margin-bottom: 15px;
}

.login-form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.login-form-group input {
    background-color: #eee;
    border: none;
    padding: 1rem;
    font-size: 1rem;
    border-radius: 1rem;
    color: black;
    box-shadow: 0 0.2rem lightgrey;
    cursor: pointer;
    width: 100%;
}

.login-form-group input:focus {
    outline-color: grey;
}

.error-message {
    margin-top: 15px;
    color: #dc3545;
    text-align: center;
}

.login-button {
    width: 100%;
    height: 40px;
    border-radius: 30px;
    border: none;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.13);
    align-items: center;
    justify-content: center;
    font-weight: 600;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    letter-spacing: 0.7px;
}

.login-button svg {
    margin-left: .2rem;
}

.login-button:disabled {
    background-color: #5a9bf8;
}

.login-button:hover:not(:disabled) {
    background-color: #0056b3;
}

.login-button:hover .arrow {
    animation: slide-in-left 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.login-button:active {
    transform: scale(0.97);
}
