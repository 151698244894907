.Footer-Background{
    width: 100%;
}
.Centralizar-De-Informações{
    display: flex;
    justify-content: space-around;
    gap: 2rem;
}
.Coluna-Informações{
    
}
.Informações{
margin-top: 1rem;
}
.About-Us{
    margin-top: 1rem;
}